import {
  Checkbox,
  Heading,
  TextInput,
  InputErrorWrapper,
  LargeText,
  Box,
  IconSize,
  Button,
  useMatchBreakpoints,
} from '@bumper-dao/ui-kit';
import React, { FC, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { UserService } from '../../services/user.service';
import { MainContainer, PixelatedContainer } from '../common/MainContainer';
import { PrivacyModal } from '../common/PrivacyModal';
import { TermsAndConditionModal } from '../common/TermsAndConditionsModal/Modal';
import { Routes } from '../routes/routes';

export const DetailsContent: FC = () => {
  const navigate = useNavigate();
  const { isMobile } = useMatchBreakpoints();
  const [email, setEmail] = useState<string>('');
  const [checked, setChecked] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [openPrivacy, setOpenPrivacy] = useState<boolean>(false);
  const [openTermsAndConditions, setOpenTermsAndConditions] =
    useState<boolean>(false);

  const { mutate, isLoading } = useMutation(
    'save-details',
    (data: { email: string }) => {
      return UserService.addUser(data);
    },
    {
      onSuccess: () => {
        navigate(Routes.WalletPage, { state: { email } });
      },
      onError: (error: Error) => {
        if (error.message === 'User already exists') {
          navigate(Routes.WalletPage, { state: { email } });
        }
      },
    },
  );

  const onStart = async () => {
    const emailRegExp =
      // eslint-disable-next-line
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailRegExp.test(email)) {
      setError('Invalid Email.');
      return;
    }

    if (!email) return;

    mutate({
      email,
    });
  };

  return (
    <MainContainer width={isMobile ? '90%' : '708px'} py="80px">
      {openPrivacy ? (
        <PrivacyModal onCloseModal={() => setOpenPrivacy(false)}></PrivacyModal>
      ) : null}

      {openTermsAndConditions ? (
        <TermsAndConditionModal
          onCloseModal={() => setOpenTermsAndConditions(false)}
        ></TermsAndConditionModal>
      ) : null}

      <InputsContainer>
        <Heading scale={'xxl'} color={'#F4EC22'}>
          Enter Email:
        </Heading>
        <InputErrorWrapper errorText={error}>
          <EmailInput
            type="text"
            value={email}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              if (error) setError('');
              setEmail(event.currentTarget.value);
            }}
            placeholder="bumperrules@bumper.com"
            isError={!!error}
          />
        </InputErrorWrapper>
      </InputsContainer>
      <CheckboxContainer>
        <Box width="32px" height="32px" pt={isMobile ? '8px' : 'none'}>
          <Checkbox
            checked={checked}
            onChange={() => setChecked((prev) => !prev)}
          />
        </Box>
        <LargeText
          color="typography.input"
          fontSize={isMobile ? '15px' : '16px'}
          fontFamily={isMobile ? 'Roboto' : 'Roboto Mono'}
        >
          By checking this box you acknowledge and agree to our{' '}
          <InlineLink onClick={() => setOpenTermsAndConditions(true)}>
            Terms and Conditions
          </InlineLink>{' '}
          and{' '}
          <InlineLink onClick={() => setOpenPrivacy(true)}>
            Privacy Policy
          </InlineLink>
          .
        </LargeText>
      </CheckboxContainer>

      <StartGameButton
        size={IconSize.L}
        primary
        onClick={onStart}
        disabled={!email || !checked || isLoading}
      >
        Start Game
      </StartGameButton>
    </MainContainer>
  );
};

const InputsContainer = styled(PixelatedContainer)`
  align-items: center;
  padding: 32px 40px;
  gap: 28px;

  ${({ theme }) => theme.mediaQueries.sm} {
    gap: 20px;
  }
`;

const CheckboxContainer = styled(PixelatedContainer)`
  margin-top: 40px;
  flex-direction: row;
  align-items: center;
  padding: 32px 40px;
  gap: 32px;

  ${({ theme }) => theme.mediaQueries.sm} {
    align-items: start;
  }
`;

const EmailInput = styled(TextInput)`
  text-align: center;
  width: 500px;
  height: unset;
  padding: 12px;
  ${({ theme }) => theme.mediaQueries.sm} {
    width: 234px;
    padding: 4px 12px;
  }
`;

export const InlineLink = styled('a')`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.secondary.green};
  display: inline;
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const StartGameButton = styled(Button)`
  width: 100%;
  margin-top: 40px;
`;
