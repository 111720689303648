import {
  Box,
  ConfirmationBox,
  DepositButton,
  Flex,
  IconSize,
  TextInput,
  InputGroup,
  LargeText,
  lightColors,
  MediumText,
  Slider,
  Text,
} from '@bumper-dao/ui-kit';
import ETHIcon from '@bumper-dao/ui-kit/dist/images/32px/tokens/ETH.svg';
import ETHIconFull from '@bumper-dao/ui-kit/dist/images/48px/tokens/eth.png';
import React, { FC } from 'react';
import styled from 'styled-components';

import { ProtectSliderProps } from './types';

import { formatStringifyNumberToDot } from '../../../utils/helpers';

export const ProtectSlider: FC<ProtectSliderProps> = ({
  balance,
  price,
  value,
  setValue,
}) => {
  return (
    <ConfirmationBox padding="24px 32px">
      <Flex flexDirection="column">
        <Text
          fontFamily="Share Tech Mono"
          fontSize="24px"
          lineHeight="36px"
          color="secondary.white"
          mb="8px"
        >
          Protection Amount
        </Text>
        <LargeText color="secondary.white" mb="48px">
          Move the slider to choose how much of your balance you want to protect
          or enter the amount in the field below.
        </LargeText>
        <Slider
          name="protectAmount"
          min={0}
          max={balance}
          value={value}
          icon={ETHIcon}
          step={0.25}
          onValueChanged={(newValue) => {
            setValue(newValue);
          }}
          thumbColor={lightColors.secondary.white}
        />
        <Flex justifyContent="space-between">
          <InputContainer>
            <Box maxWidth="240px" ml="15px" mt="5px">
              <InputGroup
                startIcon={<img src={ETHIconFull} alt="ETH" width="32px" />}
              >
                <TextInput
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    if (event.currentTarget.value === '') {
                      setValue(0);
                      return;
                    }
                    if (parseFloat(event.currentTarget.value) < 0) {
                      setValue(0);
                      return;
                    }
                    const newValue = parseFloat(event.currentTarget.value);
                    if (newValue <= balance) {
                      setValue(newValue);
                    }
                  }}
                />
              </InputGroup>
            </Box>
            <MediumText color="typography.placeholder">
              Current Value: ($
              {formatStringifyNumberToDot((value * price).toString(), 2)})
            </MediumText>
          </InputContainer>

          <DepositButton
            secondary
            selected={value === balance}
            size={IconSize.L}
            height="32px"
            mt="4px"
            onClick={() => {
              setValue(balance);
            }}
          >
            MAX
          </DepositButton>
        </Flex>
      </Flex>
    </ConfirmationBox>
  );
};

const InputContainer = styled(Flex)`
  align-items: center;
  flex-direction: row;
  gap: 20px;
  ${({ theme }) => theme.mediaQueries.sm} {
    flex-direction: column;
    align-items: start;
  }
`;
