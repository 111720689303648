import {
  Box,
  Button,
  HubPages,
  IconSize,
  LargeText,
  Pyramid,
  MediumText,
  useMatchBreakpoints,
  lightColors,
} from '@bumper-dao/ui-kit';
import cityBackgound from '@bumper-dao/ui-kit/dist/images/backgrounds/city.jpg';
import React, { FC, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import LatestPrices from './LatestPrice/LatestPrices';
import {
  AssetCardBottomBackground,
  AssetCardTopBackground,
  AssetCardWrapper,
  HubStatusWrapper,
  PositionsWrapper,
  RadiatorBottomStep,
  RadiatorStepperLineWrapper,
  RadiatorStepperWrapper,
  RadiatorTopStep,
} from './styles';
import {
  RadiatorOrder,
  RadiatorStepperLineProps,
  RadiatorStepperProps,
} from './types';
import { WalletCard } from './WalletCard';

import { STARTING_BALANCE_ETH } from '../../commons/config';
import { DefaultLocationProps } from '../../interfaces/location';
import { createPixelInsetBorder } from '../../utils/getPilexeledAtributes';
import { formatStringifyNumberToDot } from '../../utils/helpers';
import { MainContainer } from '../common/MainContainer';
import { DataFeedContext } from '../context/DataFeedContext';
import { Routes } from '../routes/routes';

const RadiatorStepperLine: React.FC<RadiatorStepperLineProps> = ({
  stepSize,
}) => {
  return (
    <RadiatorStepperLineWrapper stepSize={stepSize}>
      <RadiatorTopStep />
      <RadiatorBottomStep />
    </RadiatorStepperLineWrapper>
  );
};

const RadiatorStepper: React.FC<RadiatorStepperProps> = ({ order }) => {
  return (
    <RadiatorStepperWrapper order={order}>
      <RadiatorStepperLine stepSize="2rem" />
      <RadiatorStepperLine stepSize="1.5rem" />
      <RadiatorStepperLine stepSize="0.75rem" />
      <RadiatorStepperLine />
    </RadiatorStepperWrapper>
  );
};

export const WalletPageContent: FC<DefaultLocationProps> = ({ email }) => {
  const navigate = useNavigate();
  const { historyDataFeed, simulationDataFeed } = useContext(DataFeedContext);
  const { isMobile } = useMatchBreakpoints();

  const historyPriceChange = () => {
    if (historyDataFeed.length > 0) {
      const startPrice = parseFloat(historyDataFeed[0].price);
      const endPrice = parseFloat(
        historyDataFeed[historyDataFeed.length - 1].price,
      );
      return (((endPrice - startPrice) / startPrice) * 100).toFixed(2);
    }
    return (0.0).toFixed(2);
  };

  if (historyDataFeed?.length > 0 && simulationDataFeed?.length > 0)
    return (
      <MainContainer width={'100%'}>
        <AssetCardWrapper>
          <Box width={isMobile ? '300px' : '1100px'} zIndex={900}>
            <LargeText
              color="secondary.white"
              paddingTop={30}
              paddingBottom={20}
              style={{
                fontSize: '20px',
              }}
            >
              Your Crypto Wallet
            </LargeText>
            <MediumText color="secondary.white">
              It’s full of gameplay ETH. See the amount, price and value, along
              with the last 7 days of simulated price action.
            </MediumText>
            <br />
            <MediumText color="secondary.white">
              Your mission is to defend the price from volatility using the
              Bumper App. Press PROTECT to start the game...
            </MediumText>
          </Box>

          <AssetCardTopBackground />
          <WalletCardContainer>
            <WalletCard
              total={formatStringifyNumberToDot(STARTING_BALANCE_ETH, 4)}
              value={formatStringifyNumberToDot(
                (
                  parseFloat(simulationDataFeed[0].price) *
                  parseFloat(STARTING_BALANCE_ETH)
                ).toString(),
                2,
              )}
              price={formatStringifyNumberToDot(
                parseFloat(simulationDataFeed[0].price).toString(),
                2,
              )}
              percent={historyPriceChange() + '%'}
            />
          </WalletCardContainer>
          <AssetCardBottomBackground />
          <HubStatusWrapper>
            <RadiatorStepper order={RadiatorOrder.left} />
            <Pyramid isHubConnected={true} currentHub={HubPages.protect} />
            <RadiatorStepper order={RadiatorOrder.right} />
          </HubStatusWrapper>
        </AssetCardWrapper>
        <PositionsWrapper backgroundImage={cityBackgound}>
          {historyDataFeed?.length > 0 && <LatestPrices />}
          <Button
            size={IconSize.XL}
            style={{
              padding: '6px 72px',
              margin: '0 auto',
              backgroundColor: lightColors.secondary.darkGreen,
              boxShadow: createPixelInsetBorder(
                lightColors.primary2.darkGreen,
                lightColors.secondary.green,
                lightColors.secondary.extraDarkGreen,
                4,
              ),
            }}
            primary
            onClick={() => navigate(Routes.Protect, { state: { email } })}
          >
            Protect
          </Button>
        </PositionsWrapper>
      </MainContainer>
    );

  return null;
};

const WalletCardContainer = styled.div`
  width: 1100px;
  z-index: 20;
  @media (max-width: 768px) {
    width: 320px;
  }
`;
