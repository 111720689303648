import {
  Box,
  Close,
  Heading,
  IconSize,
  // LargeText,
  MediumText,
  // SmallText,
  useMatchBreakpoints,
} from '@bumper-dao/ui-kit';
import React, { FC } from 'react';

import { CloseButtonContainer, Container } from './styles';
import { TermsAndConditionsProps } from './types';

import { CryptoDefenderLogo } from '../CryptoDefenderLogo';
import { ModalWrapper } from '../MainContainer';

export const TermsAndConditionModal: FC<TermsAndConditionsProps> = ({
  onCloseModal,
}) => {
  const { isMobile } = useMatchBreakpoints();

  return (
    <ModalWrapper>
      <Container>
        <CloseButtonContainer onClick={onCloseModal}>
          <Close variant={IconSize.L} />
        </CloseButtonContainer>

        <Box paddingY={'20px'}>
          <CryptoDefenderLogo size={isMobile ? 100 : 50} />
        </Box>

        <Box
          height={'100%'}
          overflowY="auto"
          paddingX={'10px'}
          paddingY={'20px'}
        >
          <Heading
            scale={'xl'}
            color="secondary.white"
            width={'100%'}
            paddingBottom="20px"
            textAlign={isMobile ? 'center' : 'center'}
          >
            Terms and conditions
          </Heading>
          <MediumText color="white" textAlign="justify">
            Bumper provides information and resources about the fundamentals of
            the decentralised non-custodial liquidity protocol called the Bumper
            Protocol (the “Bumper Protocol” or “Protocol”). Bumper.fi is not one
            of the available access points to the Bumper Protocol.&nbsp;
            <br />
            <br />
            Bumper.fi is provided as an informational resource. Bumper.fi
            provides resources about the fundamentals of the Bumper Protocol,
            which is a fully decentralised, community-governed protocol deployed
            on the blockchain, and provides information about the Bumper
            ecosystem, governance, community, and various interfaces and
            integrations to the Bumper Protocol.&nbsp;
            <br />
            <br />
            All information provided in connection with your access and use of
            the Site and the Services is for informational purposes only. You
            should not take, or refrain from taking, any action based on any
            information contained on the Site or any other information that we
            make available at any time, including blog posts, data, articles,
            links to third-party content, discord content, news feeds,
            tutorials, tweets, and videos. Before you make any financial, legal,
            technical, or other decisions involving the Bumper Protocol, you
            should seek independent professional advice from a licensed and
            qualified individual in the area for which such advice would be
            appropriate.&nbsp;
            <br />
            <br />
            You acknowledge and agree that we are not responsible for the
            availability of such external sites, applications, or resources, and
            do not endorse and are not responsible or liable for any content,
            advertising, products, or other materials on or available from such
            sites or resources.&nbsp;
            <br />
            <br />
            You further acknowledge and agree that we will not be responsible or
            liable, directly or indirectly, for any damage or loss caused or
            alleged to be caused by or in connection with the use of or reliance
            on any such content, goods, or services available on or through any
            such site or resource.
            <br />
            <br />
            It is important to understand that neither we nor any affiliated
            entity is a party to any transaction on the blockchain networks
            underlying the Bumper Protocol; we do not have possession, custody,
            or control over any crypto assets appearing on the Bumper Protocol;
            and we do not have possession, custody, or control over any user’s
            funds. Further, we do not store, send, or receive any crypto assets.
            You understand that when you interact with any Bumper Protocol smart
            contracts, you retain control over your crypto assets at all times.
            The private key associated with the wallet address from which you
            transfer crypto assets or the private key associated is the only
            private key that can control the crypto assets you transfer into the
            smart contracts. You alone are responsible for securing your private
            keys. We do not have access to your private keys. Due to the
            non-custodial and decentralised nature of the technology, we are not
            intermediaries, agents, advisors, or custodians, and we do not have
            a fiduciary relationship or obligation to you regarding any other
            decisions or activities that you affect when using the Bumper
            Protocol. You acknowledge that we, for the avoidance of doubt, do
            not have any information regarding any users, users’ identities, or
            services beyond what is available or obtainable publicly via the
            blockchain. We are not responsible for any activities you engage in
            when using the Bumper Protocol, and you should understand the risks
            associated with crypto assets, and blockchain technology
            generally.&nbsp;
            <br />
            <br />
            The software underlying blockchain networks on which the Bumper
            Protocol is deployed, including, for example, the Ethereum
            blockchain, is open source, which means that anyone can use,
            utilise, and build on top of it. By using the Services, you
            acknowledge and agree (i) that we are not responsible for the
            operation of the blockchain-based software and networks underlying
            the Bumper Protocol, (ii) that there exists no guarantee of the
            functionality, security, or availability of that software and
            networks, and (iii) that the underlying blockchain-based networks
            are subject to sudden changes in operating rules, such as those
            commonly referred to as “forks”.&nbsp;
            <br />
            <br />
            Transactions on the blockchain are not anonymous.&nbsp;
            <br />
            <br />A widespread belief is that transactions involving blockchains
            are anonymous. In fact, a central feature of blockchains and thus,
            blockchain-based transactions, are that they are transparent. Your
            public key and your wallet address, which you need to buy or sell
            items on the blockchain, are visible to anyone. To the extent your
            public key or wallet address can be linked back to you, it would be
            possible for someone to determine your identity and the crypto
            assets you own.&nbsp;
            <br />
            <br />
            Transactions on the Bumper Protocol rely on smart contracts stored
            on various blockchains, cryptographic tokens generated by the smart
            contracts, and other nascent software, applications, and systems
            that interact with blockchain-based networks. These technologies are
            experimental, speculative, inherently risky, and subject to change.
            Among other risks, bugs, malfunctions, cyberattacks, or changes to
            the applicable blockchain (e.g., forks) could disrupt these
            technologies and even result in a total loss of crypto assets, their
            market value, or digital funds.&nbsp;
            <br />
            <br />
            You are solely responsible for the safekeeping of the private key
            associated with the blockchain address used to interact with the
            Protocol. We assume no liability or responsibility for any such
            risks. If you are not comfortable assuming these risks, you should
            not access or engage in transactions using blockchain-based
            technology.&nbsp;
            <br />
            <br />
            One of the other defining features of blockchain technology is that
            its entries are immutable, which means, as a technical matter, they
            generally cannot be deleted or modified by anyone. This includes
            smart contracts and crypto-assets generated and programmed by smart
            contracts.&nbsp;
            <br />
            <br />
            THUS, TRANSACTIONS RECORDED ON THE BLOCKCHAIN, INCLUDING TRANSFERS
            OF CRYPTO ASSETS AND DATA PROGRAMMED INTO THESE ASSETS (SUCH AS
            REVENUE AND INTEREST ALLOCATIONS), MUST BE TREATED AS PERMANENT AND
            CANNOT BE UNDONE BY US OR BY ANYONE. YOU MUST BE VERY CAREFUL WHEN
            YOU FINALISE ANY TRANSACTION THAT WILL BE RECORDED ON THE
            BLOCKCHAIN.&nbsp;
            <br />
            <br />
            We are not liable for any third-party services or links.&nbsp;
            <br />
            <br />
            We are not responsible for the content or services of any third
            party, including, without limitation, any network, or apps like
            Discord, or MetaMask, and we make no representations regarding the
            content or accuracy of any third-party services or materials. The
            use and access of any third-party products or services, including
            through the Bumper Protocol, is at your own risk.
            <br />
            <br />
            We reserve the right to restrict your access from engaging with the
            Bumper Protocol. You agree that we have the right to restrict your
            access to the Bumper Protocol via any technically available methods
            if we suspect, in our sole discretion, that (a) you are using the
            Bumper Protocol for money laundering or any illegal activity; (b)
            you have engaged in fraudulent activity; (c) you have acquired
            crypto assets using inappropriate methods, including the use of
            stolen funds to purchase such assets; (d) you are the target of any
            sanctions administered or enforced by the U.S. Department of the
            Treasury’s Office of Foreign Assets Control (“OFAC”), the United
            Nations Security Council, the European Union, Her Majesty’s
            Treasury, or any other legal or regulatory authority in any
            applicable jurisdiction; (e) either you, as an individual or an
            entity, or your wallet address is listed on the Specially Designated
            Nationals and Blocked Persons List (“SDN List”), Consolidated
            Sanctions List (“Non-SDN Lists), or any other sanctions lists
            administered by OFAC; (f) you are located, organised, or resident in
            a country or territory that is, or whose government is, the subject
            of sanctions; or (g) you have otherwise acted in violation of these
            Terms.&nbsp;If we have a reasonable suspicion that you are utilising
            the Site for illegal purposes, we reserve the right to take whatever
            action we deem appropriate.&nbsp;
            <br />
            <br />
            We do not guarantee the quality or accessibility of the
            Services.&nbsp;
            <br />
            <br />
            As a condition to accessing or using the Bumper Protocol or the
            Site, you acknowledge, understand, and agree that from time to time,
            the Site and the Services may be inaccessible or inoperable for any
            reason, including, but not limited to equipment malfunctions,
            periodic maintenance procedures or repairs, causes beyond our
            control or that we could not reasonably foresee, disruptions and
            temporary or permanent unavailability of underlying blockchain
            infrastructure or unavailability of third-party service providers or
            external partners for any reason.&nbsp;
            <br />
            <br />
            <strong>Taxes</strong>
            <br />
            <br />
            You are responsible for your taxes and duties. Users bear sole
            responsibility for paying any and all taxes, duties, and assessments
            now or hereafter claimed or imposed by any governmental authority
            associated with their use of the Bumper Protocol, and/or payable as
            the result of using and/or exploiting any crypto assets and
            interacting with smart contracts. Blockchain-based transactions are
            novel, and their tax treatment is uncertain.&nbsp;
            <br />
            <br />
            <strong>Prohibited Content&nbsp;</strong>
            <br />
            <br />
            You may only use the Services if you comply with this Agreement
            (including, without limitation, these Terms), applicable third-party
            policies, and all applicable laws, rules, regulations, and related
            guidance.&nbsp;
            <br />
            <br />
            The following conduct is prohibited:&nbsp;
            <br />- using the Services for, or to promote or facilitate, illegal
            activity (including, without limitation, money laundering, financing
            terrorism, tax evasion, buying or selling illegal drugs, contraband,
            counterfeit goods, or illegal weapons);
            <br />- exploiting the Services for any unauthorised commercial
            purpose;
            <br />- uploading or transmitting viruses, worms, Trojan horses,
            time bombs, cancel bots, spiders, malware, or any other type of
            malicious code that will or may be used in any way that will affect
            the functionality or operation of the Services;
            <br />- attempting to or actually copying or making unauthorised use
            of all or any portion of the Services, including by attempting to
            reverse compile, reformatting or framing, disassemble, reverse
            engineer any part of the Services;
            <br />- harvesting or otherwise collecting information from the
            Services for any unauthorised purpose;
            <br />- using the Services under false or fraudulent pretenses or
            otherwise being deceitful;
            <br />- interfering with other users’ access to or use of the
            Services;
            <br />- interfering with or circumventing the security features of
            the Services or any third party’s systems, networks, or resources
            used in the provision of Services;
            <br />- engaging in any attack, hack, denial-of-service attack,
            interference, or exploit of any smart contract in connection with
            the use of the Service (and operations performed by a user that is
            technically permitted by a smart contract may nevertheless be a
            violation of our Agreement, including these Terms, and the law); or
            <br />- engaging in any anti-competitive behavior or other
            misconduct.&nbsp;
            <br />
            <br />
            Violating our rules may result in our intervention. You agree and
            acknowledge that if you use the Bumper Protocol to engage in conduct
            prohibited by applicable law, we permanently reserve the right to
            completely or partially restrict or revoke your access to the Bumper
            Protocol, either completely or for a period of time, at our sole
            discretion. We reserve the right to amend, rectify, edit, or
            otherwise alter transaction data to remediate or mitigate any damage
            caused either to us or to any other person as a result of a user’s
            violation.&nbsp;We reserve the right to investigate violations.{' '}
            <br />
            <br />
            We reserve the right to investigate and prosecute any suspected
            breaches of this Agreement, including the Terms. We may disclose any
            information as necessary to satisfy any law, regulation, legal
            process, or governmental request.
            <br />
            <br />
          </MediumText>
        </Box>
      </Container>
    </ModalWrapper>
  );
};
