import { lightColors, Box, Flex } from '@bumper-dao/ui-kit';
import styled from 'styled-components';

import {
  RadiatorOrder,
  RadiatorStepperLineProps,
  RadiatorStepperProps,
  PositionsWrapperProps,
} from './types';

export const AssetCardTopBackground = styled('div')`
  background: ${lightColors.primary2.deepTeal};
  width: 100%;
  top: -4px;
  height: 65%;
  position: absolute;
  box-shadow: 0px 4px 0px ${lightColors.primary2.greenBlack};
`;

export const AssetCardBottomBackground = styled('div')`
  background: ${lightColors.primary2.midnightGreen};
  width: 100%;
  bottom: -4px;
  height: 35%;
  position: absolute;
  box-shadow: 0px -4px 0px ${lightColors.primary2.darkSlateGray},
    0px 4px 0px ${lightColors.primary2.darkGreen};
`;

export const AssetCardWrapper = styled(Flex)<{ bg?: boolean }>`
  position: relative;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding-bottom: 24px;
  background-color: ${({ theme, bg }) =>
    bg && theme.colors.primary2.darkRichBlack};
`;

export const HubStatusWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  overflow: hidden;
  z-index: 1;
`;

export const RadiatorStepperWrapper = styled(Flex)<RadiatorStepperProps>`
  transform: ${({ order }) =>
    order === RadiatorOrder.right ? 'scaleX(-1)' : 'none'};
  flex-direction: column;
  gap: 4px;
  padding-bottom: 4px;
`;

export const RadiatorStepperLineWrapper = styled(
  Flex,
)<RadiatorStepperLineProps>`
  flex-direction: column;
  max-width: 544px;
  margin-left: ${({ stepSize }) => stepSize};
  margin-right: -1.75rem;
  ${({ theme }) => theme.mediaQueries.sm} {
    max-width: unset;
  }
`;

export const RadiatorTopStep = styled(Box)`
  width: 544px;
  border-top: 4px solid ${({ theme }) => theme.colors.primary2.greenCyprus};
`;

export const RadiatorBottomStep = styled(Box)`
  width: 540px;
  border-bottom: 4px solid ${({ theme }) => theme.colors.primary2.myrtleGreen};
`;

export const PositionsWrapper = styled(Box)<PositionsWrapperProps>`
  width: 100%;
  padding-top: 6rem;
  padding-bottom: 4rem;
  flex: 1;

  box-shadow: 0px -8px 0px ${({ theme }) => theme.colors.primary2.deepTeal};

  & > *:not(:last-child) {
    margin-bottom: 2.5rem;
  }

  min-height: 500px;
  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  ${({ theme }) => theme.mediaQueries.sm} {
    padding-left: 30px;
    padding-right: 30px;
  }
`;

export const CoinKey = styled('span')`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.6);
`;

export const ContentWrapper = styled(Flex)`
  align-items: center;
  flex-direction: row;
  justify-content: center;
  gap: 40px;
  ${({ theme }) => theme.mediaQueries.sm} {
    flex-direction: column;
    gap: 32px;
    padding: 10px 0;
  }
`;

export const ContentHeadingWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;

  & > *:first-child {
    margin-right: 0.25rem;
  }
`;

export const CoinName = styled(Flex)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  background-color: ${({ theme }) => theme.colors.primary2.myrtleGreen};
  border: none;
  padding: 4px 20px;
  box-shadow: 4px 0 0 0 ${({ theme }) => theme.colors.primary2.myrtleGreen},
    0px 4px 0px 0px ${({ theme }) => theme.colors.primary2.myrtleGreen},
    -4px 0px 0px 0px ${({ theme }) => theme.colors.primary2.myrtleGreen},
    0px -4px 0px 0px ${({ theme }) => theme.colors.primary2.myrtleGreen},
    -8px 0px 0px 0px ${({ theme }) => theme.colors.primary2.greenBlack},
    8px 0px 0px 0px ${({ theme }) => theme.colors.primary2.greenBlack},
    0px 8px 0px 0px ${({ theme }) => theme.colors.primary2.greenBlack},
    0px -8px 0px 0px ${({ theme }) => theme.colors.primary2.greenBlack},
    0px 0px 0px 4px ${({ theme }) => theme.colors.primary2.greenBlack};
  width: fit-content;
  min-width: 177px;
  color: ${({ theme }) => theme.colors.typography.input};

  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 4px 8px;
    width: 100%;
  }
`;
export const ContentValueWrapper = styled(Flex)`
  justify-content: center;

  & > *:first-child {
    margin-right: 0.25rem;
  }
`;

export const InfoBoxContent = styled(Flex)`
  flex-direction: column;
  align-items: start;
  gap: 4px;
`;
